import apolloClient_Company from "@/services/apollo-client-company";
import {
    GET_SEEKER,
    REUSE,
    PURCHASECV,
    SAVEIS,
    RATE,
    GET_TOTAL,
  } from "@/graphql/Cv.js";

export const reuseApi = (type) => {
    return new Promise(async (resolve, reject) => {
        try {  
            const res = await apolloClient_Company.query({
                query: REUSE,
                variables: {
                  type,
                },
              });
            resolve(res.data.reuseEmp)
        } catch (e) {
            reject(e)
        }
    })
}