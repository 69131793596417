<template>
  <section class="section">
    <div class="container">
      <div class="page-header">
        <div class="page-header-start">
          <h3>
            Showing CV<span>{{ showingCV }}</span>
          </h3>
        </div>
        <div class="page-header-end stretch">
          <div class="field has-addon">
            <label class="label">Show Only </label>
            <div class="control">
              <div class="checkbox">
                <ul>
                  <li v-if="ClickSave === false" @click="
                    (ClickSave = true),
                    $router.push({
                      query: {
                        ...$route.query,
                        ClickSave: true,
                        page: 1,
                        perPage: 10,
                      },
                    })
                    ">
                    <p>Saved CV</p>
                  </li>
                  <li v-if="ClickSave === true" @click="
                    (ClickSave = false),
                    $router.push({
                      query: {
                        ...$route.query,
                        ClickSave: false,
                        page: 1,
                        perPage: 10,
                      },
                    })
                    " :class="{ checked: ClickSave }">
                    <p>Saved CV</p>
                  </li>
                  <li v-if="ClickPurchased === false" @click="
                    (ClickPurchased = true),
                    $router.push({
                      query: {
                        ...$route.query,
                        ClickPurchased: true,
                        page: 1,
                        perPage: 10,
                      },
                    })
                    ">
                    <p>Purchased CV</p>
                  </li>
                  <li v-if="ClickPurchased === true" @click="
                    (ClickPurchased = false),
                    $router.push({
                      query: {
                        ...$route.query,
                        ClickPurchased: false,
                        page: 1,
                        perPage: 10,
                      },
                    })
                    " :class="{ checked: ClickPurchased }">
                    <p>Purchased CV</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Pagination tag -->
          <div class="my-cv-paginate">
            <paginate v-model="page" :total="showingCV" />
          </div>
          <!-- End pagination -->
        </div>
      </div>
      <div class="grids is-12-desktop gap-20-desktop">
        <div class="span-3-desktop filter">
          <!-- Gender -->
          <div class="box" :class="{ hide: hide.includes('gender') }">
            <div class="field">
              <label class="label" @click="clickHide('gender')">
                Gender<i class="fa-duotone fa-chevron-down"></i>
              </label>
              <div class="control">
                <div class="checkbox">
                  <ul>
                    <li v-for="(i, index) in reuseGender" :key="index" @click="Gender(i._id)"
                      :class="{ checked: genders.includes(i._id) }">
                      <p>{{ i.name }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Marital Status -->
          <div class="box" :class="{ hide: hide.includes('status') }">
            <div class="field">
              <label class="label" @click="clickHide('status')">Marital Status <i
                  class="fa-duotone fa-chevron-down"></i></label>
              <div class="control">
                <div class="checkbox">
                  <ul>
                    <li v-for="(i, index) in reuseMarital" :key="index" @click="Marital(i._id)"
                      :class="{ checked: maritals.includes(i._id) }">
                      <p>{{ i.name }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Current job function -->
          <div class="box" :class="{ hide: hide.includes('currentjob') }">
            <div class="field">
              <label class="label" @click="clickHide('currentjob')">Job Function <i
                  class="fa-duotone fa-chevron-down"></i></label>
              <div class="control">
                <div class="checkbox scrollable has-search">
                  <div class="search">
                    <input v-model="searchText" type="text" class="input"
                      placeholder="Business administration, Banking" />
                  </div>
                  <ul>
                    <li v-for="(i, index) in getJobfunction" :key="index" @click="isJobFunctions(i._id)"
                      :class="{ checked: jobFunction.includes(i._id) }">
                      <p>{{ i.name }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Previous Job Level -->
          <div class="box" :class="{ hide: hide.includes('jobLevel') }">
            <div class="field">
              <label class="label" @click="clickHide('jobLevel')">Job Level <i
                  class="fa-duotone fa-chevron-down"></i></label>
              <div class="control">
                <div class="checkbox scrollable has-search">
                  <!-- <div class="search">
                    <input v-model="searchTextJobLevel" type="text" class="input"
                      placeholder="Business administration, Banking" />
                  </div> -->
                  <ul>
                    <li v-for="(i, index) in jobLevelInfo" :key="index" @click="isJobLevel(i._id)"
                      :class="{ checked: jobLevel.includes(i._id) }">
                      <p>{{ i.name }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Previous Working Industry -->
          <!-- <div class="box" :class="{ hide: hide.includes('industry') }">
            <div class="field">
              <label class="label" @click="clickHide('industry')"
                >Previous Working Industry
                <i class="fa-duotone fa-chevron-down"></i
              ></label>
              <div class="control">
                <div class="checkbox scrollable has-search">
                  <div class="search">
                    <input
                      v-model="searchTextIndustry"
                      type="text"
                      class="input"
                      placeholder="Business administration, Banking"
                    />
                  </div>
                  <ul>
                    <li
                      v-for="(i, index) in getPreviousIndustry"
                      :key="index"
                      @click="Industrys(i._id)"
                      :class="{ checked: industrys.includes(i._id) }"
                    >
                      <p>{{ i.name }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->

          <!-- Field of study -->
          <!-- <div class="box" :class="{ hide: hide.includes('study') }">
            <div class="field">
              <label class="label" @click="clickHide('study')"
                >Field of study <i class="fa-duotone fa-chevron-down"></i
              ></label>
              <div class="control">
                <div class="checkbox scrollable has-search">
                  <div class="search">
                    <input
                      v-model="searchTextstudy"
                      type="text"
                      class="input"
                      placeholder="Business administration, Banking"
                    />
                  </div>
                  <ul>
                    <li
                      v-for="(i, index) in getPreviousstudy"
                      :key="index"
                      @click="Educations(i.name)"
                      :class="{ checked: educations.includes(i.name) }"
                    >
                      <p>{{ i.name }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->

          <!-- Education Level -->
          <div class="box" :class="{ hide: hide.includes('education') }">
            <div class="field">
              <label class="label" @click="clickHide('education')">Education Level <i
                  class="fa-duotone fa-chevron-down"></i></label>
              <div class="control" :class="{ hide: !hide.includes('education') }">
                <div class="checkbox">
                  <ul>
                    <li v-for="(i, index) in Degree" :key="index" @click="Degrees(i._id)"
                      :class="{ checked: degrees.includes(i._id) }">
                      <p>{{ i.name }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Current Salary -->
          <div class="box" :class="{ hide: hide.includes('salary') }">
            <div class="field">
              <label class="label" @click="clickHide('salary')">Latest Salary<i
                  class="fa-duotone fa-chevron-down"></i></label>
              <div class="control">
                <div class="checkbox">
                  <ul>
                    <li v-for="(i, index) in SalaryRange" :key="index" @click="Salary(i._id)"
                      :class="{ checked: salarys.includes(i._id) }">
                      <p>{{ i.name }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Language -->
          <div class="box" :class="{ hide: hide.includes('language') }">
            <div class="field">
              <label class="label" @click="clickHide('language')">Language <i
                  class="fa-duotone fa-chevron-down"></i></label>
              <div class="control">
                <div class="checkbox">
                  <ul>
                    <li v-for="(i, index) in Language" :key="index" @click="Languagesdata(i._id)"
                      :class="{ checked: Languages.includes(i._id) }">
                      <p>{{ i.name }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Key skill -->
          <!-- <div class="box" :class="{ hide: hide.includes('skill') }">
            <div class="field">
              <label class="label" @click="clickHide('skill')"
                >Key Skill <i class="fa-duotone fa-chevron-down"></i
              ></label>
              <div class="control">
                <div class="checkbox scrollable has-search">
                  <div class="search">
                    <input
                      v-model="searchTextSkill"
                      type="text"
                      class="input"
                      placeholder="Business administration, Banking"
                    />
                  </div>
                  <ul>
                    <li
                      v-for="(i, index) in getPreviousSkill"
                      :key="index"
                      @click="Skill(i._id)"
                      :class="{ checked: skills.includes(i._id) }"
                    >
                      <p>{{ i.name }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- Loading -->
        <div class="span-9-desktop">
          <div v-for="(i, index) in seekers" :key="index">
            <div class="cv-card" :class="{ purchased: i.resume.purchasedCV }">
              <div class="left">
                <div class="rating" @click="
                  Reviews(
                    i.resume.purchasedCV,
                    i.resume._id,
                    i.resume.rate,
                    i.resume.reason
                  )
                  ">
                  <div class="stars">
                    <div class="fill-stars" :style="{ width: '' + i.resume.average + '%' }">
                      <span><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i
                          class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i
                          class="fa-solid fa-star"></i></span>
                    </div>
                    <div class="empty-stars">
                      <span><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i
                          class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i
                          class="fa-solid fa-star"></i></span>
                    </div>
                  </div>
                  <p>{{ i.resume.star.toFixed(1) }} Rating</p>
                </div>

                <div class="photo">
                  <span class="default" v-if="!i.resume.purchasedCV"><i class="fa-duotone fa-user"></i></span>
                  <span v-if="i.resume.purchasedCV" :style="{
                    'background-image': 'url(' + i.seeker.profile + ')',
                  }"></span>
                </div>

                <p><span>Update on :</span>{{ i.seeker.updatedAt }}</p>
                <p><span>Gender :</span> {{ i.seeker.gender }}</p>
                <p><span>Status :</span> {{ i.seeker.maritalStatus }}</p>
              </div>

              <div class="right">
                <!-- Add class="Saved" for active state -->
                <p @click="() => {
                  i.seeker.isSaved = !i.seeker.isSaved;
                  save(i.seeker._id);
                }
                  " class="save" :class="{ saved: i.seeker.isSaved }">
                  <span>Save</span>
                  <i class="fa-solid fa-bookmark"></i>
                </p>

                <!-- UX / UI Designer -->
                <div class="group margin-bottom-5-desktop margin-bottom-0-mobile">
                  <h1 class="job-title">{{ i.resume.latestJobTitle }}</h1>
                </div>

                <!-- Latest Salary -->
                <div class="group">
                  <label>Latest Salary :</label>
                  <ul>
                    <li>{{ i.resume.salaryRange }}</li>
                  </ul>
                </div>

                <!-- Previous Job Title -->
                <div class="group">
                  <label>Previous Job Title :</label>
                  <ul>
                    <li v-for="(e, index) in i.resume.previousJobTitles" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                </div>

                <!-- Experiences -->
                <div class="group">
                  <label>Experiences :</label>
                  <ul>
                    <li>{{ i.resume.totalWorkingExperience }} Years</li>
                  </ul>
                </div>

                <!-- Education -->
                <div class="group" v-if="i.resume.educations.lenght">
                  <label>Education :</label>
                  <ul class="list">
                    <li v-for="(e, index) in i.resume.educations" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                </div>

                <!-- Language -->
                <div class="group" v-if="i.resume.languageSkills.length">
                  <label>Language :</label>
                  <ul>
                    <li v-for="(e, index) in i.resume.languageSkills" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                </div>

                <!-- Previous Employer -->
                <div class="group" v-if="i.listShow">
                  <label>Previous Employer :</label>
                  <ul>
                    <li v-for="(e, index) in i.resume.previousEmployers" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                </div>

                <!-- Previous Working Industry -->
                <div class="group" v-if="i.listShow">
                  <label>Previous Working Industry :</label>
                  <ul>
                    <li v-for="(e, index) in i.resume.previousEmployerIndustry" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                </div>

                <!-- More btn -->
                <div class="group">
                  <label></label>
                  <div class="more-btn" @click="i.listShow = !i.listShow">
                    <a v-if="!i.listShow">More detail</a>
                    <a v-if="i.listShow">Less detail</a>
                  </div>
                </div>

                <div class="widget" :class="{ purchased: i.resume.purchasedCV }">
                  <div class="purchase-button">
                    <!-- No Purchase yet start-->
                    <div class="price-tag" v-if="!i.resume.purchasedCV" @click="
                      (downloadCV = i.resume.resume),
                      (confirmModal = !purchased),
                      $router.push({
                        name: 'my-cv',
                        query: {
                          usePoint: i.resume.point,
                          resume: i.resume._id,
                          page: $route.query.page,
                          perPage: $route.query.perPage,
                        },
                      })
                      ">
                      <span>Point required:</span>
                      <p>{{ i.resume.point }}</p>
                    </div>
                    <!-- No Purchase yet end-->

                    <!-- Purchased start-->
                    <div class="price-tag" v-if="i.resume.purchasedCV">
                      <a :href="i.resume.resume" target="_blank">Download CV</a>
                    </div>
                    <!-- Purchased end-->
                    <p>
                      <span>Purchased Time :</span> {{ i.resume.purchased }}
                    </p>
                  </div>
                  <div class="cvid" title="Click to copy seeker ID ">
                    <span v-if="copyStatus && index === isIndex">Copied</span><span v-else>CVID</span>
                    <p @click="clickCopyId(index, i.seeker.genNumber)">
                      {{ i.seeker.genNumber }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- Right -->
            </div>
          </div>
          <div class="cv-card loading" v-for="i in 10" :key="i + Math.random()" v-show="isLoading">
            <div class="left">
              <div class="photo">
                <span class="default"><i class="fa-duotone fa-user"></i></span>
              </div>
              <p></p>
            </div>
            <div class="right">
              <ul>
                <li><label></label><span></span></li>
                <li><label></label><span></span></li>
                <li><label></label><span></span></li>
                <li><label></label><span></span></li>
                <li><label></label><span></span></li>
              </ul>
            </div>
          </div>
          <div class="img-container" v-if="!showingCV">
            <img src="../assets/noresultsfond.gif" alt="noresults..." />
          </div>
        </div>
      </div>
    </div>

    <div class="modal is-active" v-if="confirmModal">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <span class="close" @click="confirmModal = false"><i class="fa-regular fa-xmark"></i></span>
          <h3 v-if="$store.getters.getProfile > $route.query.usePoint">
            Confirm your CV purchasing?
          </h3>
          <h3 v-else>insufficient CV Point</h3>
          <hr />
          <h1>{{ $route.query.usePoint }}</h1>
          <p v-if="$store.getters.getProfile >= $route.query.usePoint">
            Your CV Point will <br />
            be deducted from your account
          </p>
          <p v-else>Please contact our Sales team via email info@108.jobs</p>
          <button v-if="$store.getters.getProfile < $route.query.usePoint" class="button primary is-small"
            @click="confirmModal = false">
            Cancel
          </button>
          <button v-if="$store.getters.getProfile >= $route.query.usePoint" class="button primary is-small"
            @click="confirm()">
            Confirm
          </button>
        </div>
      </div>
    </div>

    <div class="modal completed is-active" v-if="completed">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <span class="close"><i @click="!completed" class="fa-regular fa-xmark"></i></span>
          <h3>Purchased CV completed</h3>
          <hr />
          <h1><i class="fa-sharp fa-solid fa-circle-check"></i></h1>
          <p>
            Now you can download CV <br />
            you can download CV now and later
          </p>
          <a :href="downloadCV" class="button primary is-small" @click="
            ((completed = false), (purchased = false)), (downloadCV = '')
            ">
            Download
          </a>
        </div>
      </div>
    </div>

    <div class="modal review is-active" v-if="Review">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <span @click="Review = false" class="close"><i @click="Review = false" class="fa-regular fa-xmark"></i></span>
          <h1 class="box-title">Review and Rate this CV</h1>
          <div class="rate">
            <input type="radio" v-model="rate" :value="5" name="star" id="star5" />
            <label for="star5" class="fa-regular fa-star"></label>
            <input type="radio" v-model="rate" :value="4" name="star" id="star4" />
            <label for="star4" class="fa-regular fa-star"></label>
            <input type="radio" v-model="rate" :value="3" name="star" id="star3" />
            <label for="star3" class="fa-regular fa-star"></label>
            <input type="radio" v-model="rate" :value="2" name="star" id="star2" />
            <label for="star2" class="fa-regular fa-star"></label>
            <input type="radio" v-model="rate" :value="1" name="star" id="star1" />
            <label for="star1" class="fa-regular fa-star"></label>
          </div>
          <p>What do you think about this CV ? <span>(Option)</span></p>
          <textarea class="textarea" v-model="reason" rows="5" placeholder="Comment here" />
          <div class="button-group">
            <button @click="Review = false" class="button grey is-small">
              Cancel
            </button>
            <button @click="Rate(), (Review = false)" class="button primary is-small">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { GET_SEEKER, PURCHASECV, SAVEIS, RATE } from "@/graphql/Cv.js";
import _ from "lodash";
import apolloClient_Company from "@/services/apollo-client-company";
import { reuseApi } from "@/apis/reuseApi";
import paginate from "@/components/Pagination/pagination.vue";
export default {
  components: {
    paginate,
  },
  data: () => ({
    showingCV: "",
    reason: "",
    rate: "",
    errorMessagereset: "",
    downloadCV: "",
    Review: false,
    completed: false,
    loading: false,
    ClickSave: false,
    ClickPurchased: false,
    searchTextJobLevel: "",
    searchText: "",
    searchTextIndustry: "",
    searchTextstudy: "",
    searchTextSkill: "",
    KeySkill: [],
    Language: [],
    SalaryRange: [],
    Education: [],
    Industry: [],
    Degree: [],
    jobFunctionInfo: [],
    jobLevelInfo: [],
    reuseGender: [],
    reuseMarital: [],
    seekers: [],
    hide: [
      "status",
      "currentjob",
      "jobLevel",
      "industry",
      "study",
      "education",
      "salary",
      "language",
      "skill",
    ],
    confirmModal: false,
    purchased: false,
    selectedField: "",
    showMoreDetail: false,
    genders: [],
    maritals: [],
    experiences: [],
    jobFunction: [],
    jobLevel: [],
    employers: [],
    industrys: [],
    educations: [],
    degrees: [],
    salarys: [],
    Languages: [],
    skills: [],
    page: 1,
    isLoading: true,
    copyStatus: false,
    isIndex: null,
  }),
  async created() {
    this.getReuseGender();
    this.getReuseMarital();
    this.getReuseJobFunction();
    this.getReuseEmployer();
    this.getReuseIndustry();
    this.getReuseStudy();
    this.getReuseEducation();
    this.getReuseSalary();
    this.getReuseLanguage();
    this.getReuseSkill();
  },
  watch: {
    "$route.query": {
      handler(value) {
        if (value.ClickPurchased === "false") {
          let obj = { page: 1, perPage: 10 };
          if (this.ClickSave) obj = { ClickSave: true, page: 1, perPage: 10 };
          this.$router.push({ query: obj });
        }
        if (value.ClickSave === "false") {
          let obj = { page: 1, perPage: 10 };
          if (this.ClickPurchased)
            obj = { ClickPurchased: true, page: 1, perPage: 10 };
          this.$router.push({ query: obj });
        }
        if (value) {
          this.seekers = [];
          this.getSekker();
          if (this.$route.query.rate) {
            this.rate = this.$route.query.rate;
            this.reason = this.$route.query.reason;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    page: {
      handler(val) {
        this.$router
          .push({
            ...this.$route,
            query: { ...this.$route.query, page: val },
          })
          .catch(() => { });
      },
    },
  },
  computed: {
    getJobfunction() {
      return this.jobFunctionInfo.filter((i) => {
        return i.name && i.name.toLowerCase().match(this.searchText.toLowerCase());
      })
    },
    // getJobLevels() {
    //   return this.jobLevelInfo.filter((i) => {
    //     return i.name.toLowerCase()
    //       .match(this.searchTextJobLevel.toLowerCase());
    //   });
    // },
    // getPreviousIndustry() {
    //   return this.Industry.filter((i) => {
    //     return i.name.toLowerCase()
    //       .match(this.searchTextIndustry.toLowerCase());
    //   });
    // },
    // getPreviousstudy() {
    //   return this.Education.filter((i) => {
    //     return i.name.toLowerCase().match(this.searchTextstudy.toLowerCase());
    //   });
    // },
    // getPreviousSkill() {
    //   return this.KeySkill.filter((i) => {
    //     return i.name.toLowerCase().match(this.searchTextSkill.toLowerCase());
    //   });
    // },
  },
  methods: {
    clickCopyId(index, genNumber) {
      navigator.clipboard.writeText(genNumber);
      this.copyStatus = true;
      this.isIndex = index;
      setTimeout(() => {
        this.isIndex = null;
        this.copyStatus = false;
      }, 2500);
    },
    Reviews(id, getid, rate, reason) {
      if (id) {
        this.Review = true;
        this.$router.push({
          query: {
            resume: getid,
            rate: rate,
            reason: reason,
          },
        });
      }
    },
    async Rate() {
      await apolloClient_Company.mutate({
        mutation: RATE,
        variables: {
          resumeId: this.$route.query.resume,
          rate: this.rate,
          reason: this.reason,
        },
      });
      this.Review = false;
      this.$router.back();
    },
    async purchaseCV() {
      try {
        await apolloClient_Company.mutate({
          mutation: PURCHASECV,
          variables: {
            resumeId: this.$route.query.resume,
            point: this.$route.query.usePoint,
          },
        });
        this.completed = true;
        this.confirmModal = false;
        this.purchased = true;
        this.$router.back();
      } catch ({ graphQLErrors }) {
        const errorText = graphQLErrors[0].message;
        switch (errorText) {
          case "Please recharge your cv point first":
            return (this.errorMessagereset =
              "Please recharge your cv point first");
        }
      }
    },
    Skill(id) {
      if (this.skills.includes(id)) {
        const idx = this.skills.findIndex((i) => i === id);

        this.skills.splice(idx, 1);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            skills: this.skills,
          },
        });
      } else {
        this.skills.push(id);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            skills: this.skills,
          },
        });
      }
    },
    Languagesdata(id) {
      if (this.Languages.includes(id)) {
        const idx = this.Languages.findIndex((i) => i === id);

        this.Languages.splice(idx, 1);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            Languages: this.Languages,
          },
        });
      } else {
        this.Languages.push(id);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            Languages: this.Languages,
          },
        });
      }
    },
    Salary(id) {
      if (this.salarys.includes(id)) {
        const idx = this.salarys.findIndex((i) => i === id);

        this.salarys.splice(idx, 1);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            salarys: this.salarys,
          },
        });
      } else {
        this.salarys.push(id);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            salarys: this.salarys,
          },
        });
      }
    },
    Degrees(id) {
      if (this.degrees.includes(id)) {
        const idx = this.degrees.findIndex((i) => i === id);

        this.degrees.splice(idx, 1);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            degrees: this.degrees,
          },
        });
      } else {
        this.degrees.push(id);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            degrees: this.degrees,
          },
        });
      }
    },
    Educations(id) {
      if (this.educations.includes(id)) {
        const idx = this.educations.findIndex((i) => i === id);

        this.educations.splice(idx, 1);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            educations: this.educations,
          },
        });
      } else {
        this.educations.push(id);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            educations: this.educations,
          },
        });
      }
    },
    Industrys(id) {
      if (this.industrys.includes(id)) {
        const idx = this.industrys.findIndex((i) => i === id);

        this.industrys.splice(idx, 1);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            industrys: this.industrys,
          },
        });
      } else {
        this.industrys.push(id);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            industrys: this.industrys,
          },
        });
      }
    },
    isJobLevel(id) {
      if (this.jobLevel.includes(id)) {
        const idx = this.jobLevel.findIndex((i) => i === id);

        this.jobLevel.splice(idx, 1);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            jobLevel: this.jobLevel,
          },
        });
      } else {
        this.jobLevel.push(id);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            jobLevel: this.jobLevel,
          },
        });
      }
    },
    isJobFunctions(id) {
      if (this.jobFunction.includes(id)) {
        const idx = this.jobFunction.findIndex((i) => i === id);
        this.jobFunction.splice(idx, 1);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            jobFunction: this.jobFunction,
          },
        });
      } else {
        this.jobFunction.push(id);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            jobFunction: this.jobFunction,
          },
        });
      }
    },
    Marital(id) {
      if (this.maritals.includes(id)) {
        const idx = this.maritals.findIndex((i) => i === id);

        this.maritals.splice(idx, 1);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            maritals: this.maritals,
          },
        });
      } else {
        this.maritals.push(id);
        this.$router.push({
          query: {
            ...this.$route.query,
            page: 1,
            maritals: this.maritals,
          },
        });
      }
    },
    Gender(id) {
      const route = {
        query: { ...this.$route.query, page: 1, genders: this.genders },
      };
      if (this.genders.includes(id)) {
        const idx = this.genders.findIndex((i) => i === id);
        this.genders.splice(idx, 1);
        this.$router.push(route);
      } else {
        this.genders.push(id);
        this.$router.push(route);
      }
    },
    //ແຖວລຸ່ມນີ້ແມ່ນສ້າງຂື້ນມາເພື່ອເລືອກຂໍ້ມູນ reuse
    async getReuseSkill() {
      const type = "KeySkillStatic";
      const res = await reuseApi(type);
      this.KeySkill = res;
    },
    async getReuseLanguage() {
      const type = "Language";
      const res = await reuseApi(type);
      this.Language = res;
    },
    async getReuseSalary() {
      const type = "SalaryRange";
      const res = await reuseApi(type);
      this.SalaryRange = res;
    },
    async getReuseEducation() {
      const type = "Degree";
      const res = await reuseApi(type);
      this.Degree = res;
    },
    async getReuseStudy() {
      const type = "EducationStatic";
      const res = await reuseApi(type);
      this.Education = res;
    },
    async getReuseIndustry() {
      const type = "IndustryStatic";
      const res = await reuseApi(type);
      this.Industry = res;
    },
    async getReuseEmployer() {
      const type = "JobLevel";
      const res = await reuseApi(type);
      this.jobLevelInfo = res;
    },
    async getReuseJobFunction() {
      const type = "JobFunctionStatic";
      const res = await reuseApi(type);
      this.jobFunctionInfo = res;
    },
    async getReuseMarital() {
      const type = "MaritalStatus";
      const res = await reuseApi(type);
      this.reuseMarital = res;
    },
    async getReuseGender() {
      const type = "Gender";
      const res = await reuseApi(type);
      this.reuseGender = res;
    },
    async save(id) {
      await apolloClient_Company.mutate({
        mutation: SAVEIS,
        variables: {
          seekerId: id,
        },
      });
    },

    async getSekker() {
      this.isLoading = true;
      const res = await apolloClient_Company.query({
        query: GET_SEEKER,
        variables: {
          gender: this.genders,
          maritralStatus: this.maritals,
          jobFunction: this.jobFunction,
          jobLevel: this.jobLevel,
          previousWorkingIndustry: this.industrys,
          field_Of_Study: this.educations,
          current_Salary: this.salarys,
          education_Level: this.degrees,
          language: this.Languages,
          // totalExperience: [],
          keySkill: this.skills,
          isPurchase: this.ClickPurchased,
          isSave: this.ClickSave,
          page: parseInt(this.$route.query.page) || 1,
          perPage: parseInt(this.$route.query.perPage) || 10,
        },
      });
      this.isLoading = false;
      const get = res.data.filter_Seeker.seeker_List.map((i) => {
        return {
          ...i,
          listShow: false,
        };
      });

      this.seekers.push(...get);
      this.seekers = _.uniqBy(this.seekers, "seeker._id");
      this.showingCV = res.data.filter_Seeker.totals;
    },
    // readmore() {
    //   this.page++;
    //   this.getSekker();
    // },
    clickHide(i) {
      if (this.hide.includes(i)) {
        const index = this.hide.findIndex((o) => o === i);
        this.hide.splice(index, 1);
      } else {
        this.hide.push(i);
      }
    },
    selectField(index) {
      this.selectedField = index;
    },
    confirm() {
      this.purchaseCV();
    },
  },
};
</script>

<style lang="scss" scoped>
.my-cv-paginate {
  display: flex;
  align-items: center;
}

.img-container {
  width: 100%;

  img {
    width: 100%;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
  background-color: var(--bg-color);

  h3 {
    font-weight: 700;
  }

  .page-header-end {
    display: flex;
    align-items: center;

    &.stretch {
      align-items: stretch;
    }

    .field {
      display: flex;
      align-items: center;
      margin: 0;

      .label {
        font-weight: normal;
        margin-right: 0;
        border: none !important;
        height: auto !important;
      }

      .control {
        padding: 0 !important;
      }
    }
  }
}

.cv-card {
  border: 1px solid var(--border-color);
  padding: 20px;
  background-color: #fff;
  display: flex;
  gap: 10px;
  margin-bottom: 16px;

  &.purchased {
    .right {
      .widget {
        label {
          color: var(--dark-grey-color);
          font-size: var(--sm-font);
          pointer-events: none;
        }

        .price-tag {
          background-color: var(--primary-color);
          color: #fff;

          a {
            color: #fff;
          }
        }
      }
    }
  }

  .left {
    // max-width: 180px;
    // min-width: 180px;

    .rating {
      display: flex;
      align-items: center;
      margin: 0 0 5px 0;

      .stars {
        display: flex;
        flex-wrap: nowrap;
        color: var(--grey-color);
        position: relative;
        font-size: var(--xsm-font);

        .empty-stars {
          span {
            display: flex;
            flex-wrap: nowrap;
          }
        }

        .fill-stars {
          color: var(--info-color);
          position: absolute;
          z-index: 1;
          overflow: hidden;
          display: flex;
          flex-wrap: nowrap;

          span {
            display: flex;
            flex-wrap: nowrap;
          }
        }
      }

      p {
        white-space: nowrap;
        font-size: var(--xxsm-font);
        margin: 0 0 0 5px !important;
      }
    }

    .photo {
      min-width: 130px;
      max-width: 130px;
      min-height: 130px;
      max-height: 130px;
      background-color: var(--light-grey-color);
      margin-bottom: 10px;
      position: relative;

      span {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 3px solid var(--info-color);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;

        &.default {
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: var(--xxlg-font);
            color: var(--grey-color);
          }
        }
      }
    }

    p {
      margin-top: 5px;
      display: flex;
      font-size: var(--xxsm-font);
      line-height: 1.2;

      span {
        white-space: nowrap;
        color: var(--dark-grey-color);
        font-size: var(--xxsm-font);
        margin-right: 5px;
      }
    }
  }

  .right {
    width: 100%;
    flex-grow: 1;
    margin-left: 10px;
    position: relative;

    .save {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      gap: 5px;
      align-items: center;

      span {
        font-size: var(--xsm-font);
        color: var(--dark-grey-color);
      }

      i {
        color: var(--dark-grey-color);
      }

      &.saved i {
        color: var(--primary-color);
      }
    }

    // bookmark
    .group {
      display: flex;
      margin-bottom: 5px;

      .job-title {
        font-weight: 700;
        margin: 0;
        font-size: var(--md-font);
      }

      h3 {
        font-weight: 700;
        margin: 0;

        span {
          font-weight: normal;
          margin-right: 5px;
        }
      }

      label {
        font-size: var(--sm-font);
        color: var(--dark-grey-color);
        min-width: 160px;
        max-width: 160px;
        position: relative;
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        &.list {
          li {
            background-color: var(--light-grey-color);
            padding: 3px 5px;
            border-radius: 3px;
          }
        }

        li {
          font-size: var(--sm-font);
          // display: flex;
          display: block;

          span:not(:last-child) {
            margin-right: 5px;
          }

          &:not(:last-child) {
            &::after {
              content: ",";
              margin-right: 8px;
            }
          }

          &:last-child {
            &::after {
              content: ".";
              margin-right: 8px;
            }
          }
        }
      }

      .more-btn a {
        color: var(--alert-color);
        font-size: var(--sm-font);
      }
    }
  }

  .widget {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--border-color);
    padding-top: 10px;
    margin-top: 10px;
    font-size: var(--sm-font);

    label {
      color: var(--dark-grey-color);
      font-size: var(--sm-font);
      pointer-events: none;
    }

    .purchase-button {
      display: flex;
      align-items: center;
      gap: 10px;

      .price-tag {
        cursor: pointer;
        padding: 3px 10px;
        background-color: var(--info-color);
        color: var(--text-color);
        border-radius: 5px;
        display: flex;
        font-size: var(--sm-font);

        p {
          font-weight: 700;
        }

        span {
          margin-right: 5px;
          font-size: var(--sm-font);
        }
      }
    }

    .cvid {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        color: var(--text-color);
      }

      p {
        cursor: pointer;
        background-color: var(--light-grey-color);
        padding: 3px 10px;
        font-size: var(--sm-font);
        border-radius: var(--radius);
      }
    }
  }
}

// Loading Card

@mixin loading {
  content: "";
  display: block;
  margin: 0;
  padding: 0;
  background-color: var(--light-grey-color);
  border-radius: 5px;
}

.cv-card.loading {
  .left {
    max-width: 150px;
    min-width: 150px;

    .photo {
      background-color: var(--light-grey-color);
      border: none !important;
      margin-bottom: 5px;
      border-radius: 5px;

      span {
        border: none;
      }

      * {
        display: none;
      }
    }

    p {
      @include loading;
      width: 130px;
      height: 10px;
    }
  }

  // left
  .right {
    width: 100%;
    flex-grow: 1;

    ul {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 5px;

        label {
          @include loading;
          width: 150px;
          height: 20px;
        }

        span {
          @include loading;
          width: 100%;
          height: 20px;
          margin-left: 20px;
        }
      }
    }
  }
}

// Loading
.filter {
  .box {
    padding: 0;
    margin: 0 0 0 0;
    border-width: 1px 1px 0 1px;

    &:last-child {
      border-bottom-width: 1px;
    }

    // Checkbox\
    &.hide {
      .control {
        padding: 0 !important;
        height: 0 !important;
      }

      label {
        border: none !important;

        i {
          transform: rotate(0deg) !important;
        }
      }
    }
  }

  // box
}

.field {
  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color);
    height: 50px;
    display: flex;
    align-items: center;
    margin: 0;

    i {
      font-size: var(--sm-font);

      transform: rotate(180deg);
    }
  }

  &.has-addon {
    .control .checkbox ul {
      display: flex;
      gap: 10px;
      align-items: center;

      li {
        margin: 0;
        border: 1px solid var(--border-color);
        background-color: var(--light-grey-color);
        padding: 5px 10px;

        p {
          &::before {
            border: 1px solid var(--grey-color);
            color: var(--grey-color);
          }
        }

        &.checked {
          border: 1px solid var(--primary-color);
          background-color: rgba(var(--primary-color), 0.09);

          p::before {
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            color: #fff;
          }
        }
      }
    }
  }

  .control {
    padding: 20px;
    overflow: hidden;

    &.is-fullwidth {
      width: 100%;
    }

    small {
      margin-top: 5px;
      color: var(--dark-grey-color) !important;
      font-size: var(--xsm-font);
    }

    .checkbox {
      display: flex;
      flex-direction: column;
      width: 100%;

      .search {
        width: 100%;
        margin-bottom: 10px;
      }

      &.scrollable ul {
        overflow: auto;
        height: 200px;
      }

      ul {
        gap: 10px;

        li {
          display: block;
          line-height: 1.5;
          margin-bottom: 8px;

          p {
            font-size: var(--sm-font);
            display: flex;
            align-items: flex-start;
            position: relative;
            margin: 0 !important;

            &::before {
              content: "\f00c";
              font-family: "Font Awesome 6 Pro" !important;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              top: 3px;
              max-width: 18px;
              max-height: 18px;
              min-width: 18px;
              min-height: 18px;
              border: 1px solid var(--border-color);
              margin-right: 10px;
              font-size: var(--xsm-font);
              color: var(--light-grey-color);
            }
          }

          &.checked p::before {
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            color: #fff;
          }
        }

        // li
      }

      // ul
    }

    //checkbox
  }
}

.modal {
  .modal-content {
    max-width: 300px;

    .box {
      border-radius: 10px;
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--text-color);
      overflow: hidden;
      position: relative;
      overflow: hidden;
      border: none;

      .close {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
        background-color: var(--alert-color);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 0 10px;
        cursor: pointer;

        i {
          color: #fff;
        }
      }

      hr {
        margin: 10px 0;
        background-color: var(--primary-color);
        height: 2px;
        width: 50px;
        border-radius: 2px;
      }

      h3 {
        font-size: var(--sm-font);
        color: var(--text-color);
        font-weight: 700;
      }

      p {
        text-align: center;
        font-size: var(--sm-font);
        padding: 10px 0 15px;
      }

      h1 {
        font-weight: 700;
        font-size: var(--xxlg-font);
        color: var(--alert-color);
        margin: 0;
        padding: 15px 0;
        line-height: 1;
      }
    }

    // box
  }

  &.completed {
    h1 {
      color: var(--success-color);
      animation: bubble 0.5;
      transform-origin: center;
    }

    @keyframes bubble {
      from {
        transform: scale(0.1);
      }

      to {
        transform: scale(1);
      }
    }
  }

  // completed
  &.review {
    .modal-content {
      text-align: left;

      .box {
        align-items: flex-start;

        .box-title {
          font-weight: 600;
          color: var(--text-color);
          font-size: var(--sm-font);
          margin: 0;
          padding: 0;
        }

        .rate {
          margin: 8px 0px 15px;

          input {
            display: none;

            &:not(:checked)~label:hover,
            &:not(:checked)~label:hover~label,
            &:checked~label {
              font-weight: 700;
              color: var(--alert-color);
            }
          }

          label {
            transition: all ease-in-out 0.1s;
            float: right;
            color: var(--grey-color);
            cursor: pointer;
          }
        }

        p {
          line-height: 1;
          margin: 0 0 8px;
          padding: 0;
          font-size: var(--xsm-font);
          color: var(--text-color);
        }

        .button-group {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
        }
      }

      // box
    }
  }
}

// modal
</style>
