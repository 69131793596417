import gql from "graphql-tag";

export const GET_SEEKER = gql`
  query(
    $gender: [ID]
    $maritralStatus: [ID]
    $jobFunction: [ID]
    $jobLevel: [ID]
    $previousWorkingIndustry: [ID]
    $field_Of_Study: [String]
    $education_Level: [ID]
    $current_Salary: [ID]
    $language: [ID]
    $totalExperience: [Int]
    $keySkill: [String]
    $isPurchase: Boolean
    $isSave: Boolean
    $page: Int
    $perPage: Int
  ) {
    filter_Seeker(
      gender: $gender
      maritralStatus: $maritralStatus
      jobFunction: $jobFunction
      jobLevel: $jobLevel
      previousWorkingIndustry: $previousWorkingIndustry
      field_Of_Study: $field_Of_Study
      current_Salary: $current_Salary
      education_Level: $education_Level
      language: $language
      totalExperience: $totalExperience
      keySkill: $keySkill
      isPurchase: $isPurchase
      isSave: $isSave
      page: $page
      perPage: $perPage
    ) {
      seeker_List {
        seeker {
          _id
          fullName
          profile
          gender
          maritalStatus
          professionalSummary
          updatedAt
          isSaved
          genNumber
          CVStatus
        }
        resume {
          _id
          point
          resume
          purchasedCV
          purchased
          rate
          rate_Point
          reason
          star
          average
          latestJobTitle
          previousJobTitles
          previousEmployers
          previousEmployerIndustry
          totalWorkingExperience
          educations
          languageSkills
          salaryRange
          keySkills
        }
      }
      totals
    }
  }
`;

export const REUSE = gql`
  query($type: String) {
    reuseEmp(type: $type) {
      _id
      name
      laoName
    }
  }
`;
export const PURCHASECV = gql`
  mutation($resumeId: ID, $point: Int) {
    purchaseCV(info: { resumeId: $resumeId, point: $point })
  }
`;

export const SAVEIS = gql`
  mutation($seekerId: ID) {
    empSaveS(info: { seekerId: $seekerId })
  }
`;
export const RATE = gql`
  mutation($resumeId: ID, $rate: Int, $reason: String) {
    rating(info: { resumeId: $resumeId, rate: $rate, reason: $reason }) {
      _id
      resumeId {
        _id
      }
      rate
      point
      reason
    }
  }
`;
export const GET_TOTAL = gql`
  query(
    $gender: [ID]
    $maritralStatus: [ID]
    $latestJobTitle: [String]
    $previousEmployers: [String]
    $previousWorkingIndustry: [ID]
    $field_Of_Study: [String]
    $education_Level: [ID]
    $current_Salary: [ID]
    $language: [ID]
    $totalExperience: [Int]
    $keySkill: [String]
    $isPurchase: Boolean
    $isSave: Boolean
  ) {
    getTotals(
      gender: $gender
      maritralStatus: $maritralStatus
      latestJobTitle: $latestJobTitle
      previousEmployers: $previousEmployers
      previousWorkingIndustry: $previousWorkingIndustry
      field_Of_Study: $field_Of_Study
      current_Salary: $current_Salary
      education_Level: $education_Level
      language: $language
      totalExperience: $totalExperience
      keySkill: $keySkill
      isPurchase: $isPurchase
      isSave: $isSave
    )
  }
`;